import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as config from '@config';
import { Table, Checkbox, Dimmer, Loader, Modal, Button, Form } from 'semantic-ui-react';
import { GetToken } from '@utils/storage';
import { useId } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

function OperatorTable({ users = [], callback = () => {} }) {
    return (
        <Table celled striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>子账号</Table.HeaderCell>
                    <Table.HeaderCell>姓名</Table.HeaderCell>
                    <Table.HeaderCell>登录</Table.HeaderCell>
                    <Table.HeaderCell>启用</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {users.map((item, index) => {
                    return <OperatorTableRow key={index} user={item} callback={callback} />;
                })}
            </Table.Body>
        </Table>
    );
}

OperatorTable.propTypes = {
    users: PropTypes.array.isRequired,
    callback: PropTypes.func.isRequired,
};

function OperatorTableRow({ user = {}, callback = () => {} }) {
    const formID = useId();
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [open, setOpen] = useState(false);

    const controller = new AbortController();

    useEffect(() => {
        return () => {
            if (submitting) controller.abort();
        };
    }, []);

    const handleSwitchApprove = () => {
        console.log(user.id);
        setSubmitting(true);
        const req = {
            approved: !user.approved,
        };
        axios
            .patch(`${config.apiURL}/user/${user.id}`, req, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                callback();
                console.log(res.data.reply);
                window.alert(`${!user.approved ? '子账户已激活' : '已限制子账户访问'}`);
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleOnChange = (e, { name, value }) => {
        setErrors({}); // reset errors
        setData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const handleSubmit = () => {
        const errs = {};
        if (data.password.length < 6) {
            errs.password = '密码过短';
        }
        if (data.password != data.rePassword) {
            errs.rePassword = '密码不一致';
        }
        if (Object.keys(errs).length != 0) {
            setErrors(errs);
            return;
        }
        const req = {
            contactName: data.contactName || user.contactName,
            passwordText: data.password,
        };
        setSubmitting(true);
        axios
            .patch(`${config.apiURL}/user/${user.id}`, req, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                callback();
                setOpen(false);
                console.log(res.data.reply);
                const reply = res.data.reply;
                if (reply) {
                    alert(reply);
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Table.Row>
            <Table.Cell>
                {user.email}{' '}
                <Button compact onClick={() => setOpen(true)}>
                    设置
                </Button>
            </Table.Cell>
            <Table.Cell>{user.contactName}</Table.Cell>
            <Table.Cell>{user.lastReqTime && dayjs().locale('zh-cn').to(dayjs(user.lastReqTime))}</Table.Cell>
            <Table.Cell>
                <Checkbox checked={user.approved} onChange={handleSwitchApprove} />
            </Table.Cell>
            <Dimmer active={submitting} page inverted>
                <Loader></Loader>
            </Dimmer>
            <Modal closeIcon onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
                <Modal.Header>{user.email}</Modal.Header>
                <Modal.Content>
                    <Form id={formID} onSubmit={handleSubmit} autoComplete="off">
                        <Form.Group widths="equal">
                            <Form.Input
                                autoComplete="new-password"
                                name="contactName"
                                placeholder="联系人"
                                label="联系人"
                                value={data.contactName == null ? user.contactName : data.contactName}
                                onChange={handleOnChange}
                                required
                                error={errors.contactName}
                            />
                            <Form.Input
                                autoComplete="new-password"
                                name="password"
                                placeholder="新密码"
                                label="新密码"
                                value={data.password || ''}
                                onChange={handleOnChange}
                                required
                                error={errors.password}
                            />
                            <Form.Input
                                autoComplete="new-password"
                                name="rePassword"
                                placeholder="密码确认"
                                label="密码确认"
                                value={data.rePassword || ''}
                                onChange={handleOnChange}
                                required
                                error={errors.rePassword}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="关闭" onClick={() => setOpen(false)} />
                    <Button content="重置" form={formID} primary />
                </Modal.Actions>
            </Modal>
        </Table.Row>
    );
}

OperatorTableRow.propTypes = {
    user: PropTypes.object.isRequired,
    callback: PropTypes.func,
};

export default OperatorTable;
