import React, { useEffect, useState } from 'react';
import BackBar from '@components/BackBar';
import * as config from '@config';
import axios from 'axios';
import { GetToken } from '@utils/storage';
import { Table, TableBody, TableRow, TableCell, Loader, Dimmer, Icon } from 'semantic-ui-react';

let controller = new AbortController();

function FlightPassengers() {
    const [passengers, setPassengers] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        fetchPassengers();
        return () => {
            if (fetching) controller.abort();
        };
    }, []);

    const fetchPassengers = () => {
        setFetching(true);
        axios
            .get(`${config.apiURL}/flight/passengers`, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                const passengers = res.data.passengers;
                if (passengers) {
                    setPassengers(passengers);
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setFetching(false);
            });
    };

    const handleDelete = (item) => {
        if (confirm(`删除 ${item.showName}?`) != true) return;
        const url = `${config.apiURL}/flight/passenger/${item.id}`;
        const opt = {
            signal: controller.signal,
            headers: { Authorization: `Bearer ${GetToken()}` },
        };
        setDeleting(true);
        axios
            .delete(url, opt)
            .then((res) => {
                fetchPassengers();
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setDeleting(false);
            });
    };

    return (
        <div>
            <BackBar />
            <br />
            <div>
                {passengers.length == 0 && <h3>暂无数据</h3>}
                <Table striped>
                    <TableBody>
                        {passengers.map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.dob}</TableCell>
                                    <TableCell>{item.gender}</TableCell>
                                    <TableCell>{item.nationalityCode}</TableCell>
                                    <TableCell>{item.cardTypeName}</TableCell>
                                    <TableCell>{item.cardNumber}</TableCell>
                                    <TableCell>
                                        <Icon
                                            name="trash alternate outline"
                                            color="red"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleDelete(item)}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            <Dimmer page inverted active={fetching || deleting}>
                <Loader>
                    {fetching && '加载中'}
                    {deleting && '删除'}
                </Loader>
            </Dimmer>
        </div>
    );
}

export default FlightPassengers;
