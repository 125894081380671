import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

export const DefaultData = {
    email: '',
    reEmail: '',
    password: '',
    rePassword: '',
    contactName: '',
    mobile: '',
    wechat: '',
};

function OperatorAccountForm({ formID, data = DefaultData, setData = () => {}, handleSubmit = () => {} }) {
    const [errors, setErrors] = useState({});

    const handleOnChange = (e, { name, value }) => {
        setErrors({});
        setData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const onSubmit = () => {
        const newErrors = {};
        if (data.email != data.reEmail) {
            newErrors.email = '邮箱不一致';
            newErrors.reEmail = '邮箱不一致';
        }
        if (data.password.length < 6) {
            newErrors.password = '密码过短';
        }
        if (data.password != data.rePassword) {
            newErrors.rePassword = '密码不一致';
        }
        if (Object.keys(newErrors).length != 0) {
            setErrors(newErrors);
            return;
        }
        handleSubmit();
    };

    return (
        <Form id={formID} onSubmit={onSubmit} autoComplete="off">
            <Form.Group widths={'equal'}>
                <Form.Input
                    name="email"
                    label="邮箱"
                    placeholder="邮箱"
                    value={data.email || ''}
                    onChange={handleOnChange}
                    autoCapitalize="none"
                    required
                    type="text"
                    error={errors.email}
                    autoComplete="off"
                />
                <Form.Input
                    name="reEmail"
                    label="确认邮箱"
                    placeholder="确认邮箱"
                    value={data.reEmail || ''}
                    onChange={handleOnChange}
                    autoCapitalize="none"
                    required
                    type="text"
                    error={errors.reEmail}
                    autoComplete="off"
                />
            </Form.Group>
            <Form.Group widths={'equal'}>
                <Form.Input
                    name="password"
                    label="密码"
                    placeholder="密码"
                    value={data.password || ''}
                    onChange={handleOnChange}
                    autoCapitalize="none"
                    required
                    type="text"
                    error={errors.password}
                    autoComplete="off"
                />
                <Form.Input
                    name="rePassword"
                    label="确认密码"
                    placeholder="确认密码"
                    value={data.rePassword || ''}
                    onChange={handleOnChange}
                    autoCapitalize="none"
                    required
                    type="text"
                    error={errors.rePassword}
                    autoComplete="off"
                />
            </Form.Group>
            <Form.Group widths={'equal'}>
                <Form.Input
                    name="contactName"
                    label="姓名"
                    placeholder="姓名"
                    value={data.contactName || ''}
                    onChange={handleOnChange}
                    autoCapitalize="none"
                    required
                    type="text"
                    error={errors.contactName}
                    autoComplete="off"
                />
                <Form.Input
                    name="mobile"
                    label="手机"
                    placeholder="手机"
                    value={data.mobile || ''}
                    onChange={handleOnChange}
                    autoCapitalize="none"
                    type="text"
                    error={errors.mobile}
                    autoComplete="off"
                />
                <Form.Input
                    name="wechat"
                    label="微信"
                    placeholder="微信"
                    value={data.wechat || ''}
                    onChange={handleOnChange}
                    autoCapitalize="none"
                    type="text"
                    error={errors.wechat}
                    autoComplete="off"
                />
            </Form.Group>
        </Form>
    );
}

OperatorAccountForm.propTypes = {
    formID: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    setData: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default OperatorAccountForm;
