import React from 'react';
import { Route, Routes } from 'react-router';
import AccountPage from './AccountPage';
import FlightPassengers from './FlightPassengers';
import FlightContacts from './FlightContacts';
import BackBar from '@components/BackBar';

function Index() {
    return (
        <Routes>
            <Route path="" exact element={<AccountPage />} />
            <Route path="/flight/passengers" element={<FlightPassengers />} />
            <Route path="/flight/contacts" element={<FlightContacts />} />

            <Route
                path="*"
                element={
                    <div>
                        <BackBar />
                        <p>Not Found</p>
                    </div>
                }
            />
        </Routes>
    );
}

export default Index;
