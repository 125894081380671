import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as config from '@config';
import { Loader, Button, Modal, Form, Segment } from 'semantic-ui-react';
import styles from '@styles/AccountPage.module.css';
import { logout } from '@features/account/accountSlice';
import { useDispatch } from 'react-redux';
import { GetRoleName, AgentRole } from '@utils/constants';
import { Link, useSearchParams } from 'react-router-dom';
import { GetToken, IsChannelUser, WhiteLabelName } from '@utils/storage';
import OperatorAccountForm, { DefaultData } from '@components/OperatorAccountForm';
import { useId } from 'react';
import OperatorTable from '@components/OperatorTable';
import { BillingType } from '../../utils/constants';
import Incentive from '../../components/Incentive';

function AccountPage() {
    const formID = useId();
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [billingInfo, setBillingInfo] = useState();
    const [fetchingBillingInfo, setFetchingBillingInfo] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [modalEditActive, setModalEditActive] = useState(false);
    const [paymentCardActive, setPaymentCardActive] = useState(false);
    const [searchParams] = useSearchParams();
    const [operatorReq, setOperatorReq] = useState(DefaultData);

    const companyNameRef = useRef();
    const contactNameRef = useRef();
    const contactNumberRef = useRef();

    const controller = new AbortController();

    useEffect(() => {
        fetchData();
        return () => {
            if (fetching || submitting) {
                controller.abort();
            }
        };
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        if (params.get('add-card')) {
            setPaymentCardActive(true);
        }
    }, [searchParams]);

    useEffect(() => {
        if (modalActive == false) {
            setOperatorReq(DefaultData);
        }
    }, [modalActive]);

    const fetchData = async () => {
        setFetching(true);
        try {
            const res = await axios.get(`${config.apiURL}/users`, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            });
            if (res.data.error) {
                window.alert(res.data.error);
            } else if (res.data) {
                const data = res.data;
                console.log(data);
                setData(data);
            }
        } catch (error) {
            const errMsg = error.response?.data?.error;
            if (!!errMsg) {
                window.alert(errMsg);
            } else {
                window.alert(error);
            }
        }
        setFetching(false);
    };

    // const fetchBillingInfo = () => {
    //     setFetchingBillingInfo(true);
    //     axios
    //         .get(`${config.apiURL}/flights/user/billingInfo`, {
    //             signal: controller.signal,
    //             headers: { Authorization: `Bearer ${GetToken()}` },
    //         })
    //         .then((res) => {
    //             const billingInfo = res.data.billingInfo;
    //             console.log(billingInfo);
    //             if (billingInfo) {
    //                 setBillingInfo(billingInfo);
    //             }
    //         })
    //         .catch((err) => {
    //             let msg = err.toString();
    //             if (err.response?.data?.error) {
    //                 console.log('***', err.response.data.error);
    //                 msg = err.response.data.error;
    //             }
    //             window.alert(msg);
    //         })
    //         .finally(() => setFetchingBillingInfo(false));
    // };

    // const SubAccounts = () => {
    //     if (data.user.role != AgentRole.Manager) return <></>;
    //     return (
    //         <>
    //             <Button icon="add" onClick={() => setModalActive(true)} />
    //             <OperatorTable users={data.agents} callback={fetchData} />
    //             <Modal
    //                 closeIcon
    //                 onClose={() => setModalActive(false)}
    //                 onOpen={() => setModalActive(true)}
    //                 open={modalActive}
    //             >
    //                 <Modal.Header>创建子账号</Modal.Header>
    //                 <Modal.Content>
    //                     <OperatorAccountForm
    //                         formID={formID}
    //                         data={operatorReq}
    //                         setData={setOperatorReq}
    //                         handleSubmit={handleCreate}
    //                     />
    //                 </Modal.Content>
    //                 <Modal.Actions>
    //                     <Button content="关闭" onClick={() => setModalActive(false)} />
    //                     <Button content="创建" primary form={formID} />
    //                 </Modal.Actions>
    //             </Modal>
    //         </>
    //     );
    // };

    const AccountInfo = () => {
        return (
            <Segment>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <span>公司名称： {data.user.agency.companyName}</span>
                    <span>账户类型： {GetRoleName(data.user?.role)}</span>
                    <span>账户邮箱： {data.user.email}</span>
                    <span>联系人： {data.user.contactName}</span>
                    <span>联系号码： {data.user.contactNumber}</span>
                </div>
                <div style={{ marginTop: '10px' }}></div>
                <Button className="primary_btn" onClick={() => setModalEditActive(true)}>
                    修改
                </Button>
                <Modal
                    open={modalEditActive}
                    onOpen={() => setModalEditActive(true)}
                    onClose={() => setModalEditActive(false)}
                    closeIcon
                >
                    <Modal.Header>修改</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Input
                                    name="companyName"
                                    label="公司名称"
                                    placeholder="公司名称"
                                    defaultValue={data.user.agency.companyName || ''}
                                    required
                                    autoComplete="off"
                                >
                                    <input ref={companyNameRef} />
                                </Form.Input>
                                <Form.Input
                                    name="contactName"
                                    label="联系人"
                                    placeholder="联系人"
                                    defaultValue={data.user.contactName}
                                    required
                                    autoComplete="off"
                                >
                                    <input ref={contactNameRef} />
                                </Form.Input>
                                <Form.Input
                                    name="contactNumber"
                                    label="联系号码"
                                    placeholder="联系号码"
                                    defaultValue={data.user.contactNumber}
                                    autoComplete="off"
                                >
                                    <input ref={contactNumberRef} />
                                </Form.Input>
                            </Form.Group>
                            如需修改密码，请登出后选择重设密码。
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content="更新" onClick={handleUpdate} positive />
                    </Modal.Actions>
                </Modal>
            </Segment>
        );
    };

    const ChannelInfo = () => {
        return (
            <Segment>
                <div>Channel: {WhiteLabelName()}</div>
            </Segment>
        );
    };

    // const FlightSettings = () => {
    //     return (
    //         <Segment loading={fetchingBillingInfo}>
    //             <h3>机票</h3>
    //             <p>
    //                 {billingInfo ? (
    //                     <div>
    //                         付款方式：{billingInfo.billingType}
    //                         {billingInfo.billingTypeCode == BillingType.Credit &&
    //                             ` (${billingInfo.remaining}/${billingInfo.total} ${billingInfo.currency})`}
    //                     </div>
    //                 ) : (
    //                     <Button onClick={fetchBillingInfo}>查看付款方式</Button>
    //                 )}
    //             </p>
    //             <p>
    //                 <Link to={'flight/contacts'}>常用联系人</Link>
    //             </p>
    //             <p>
    //                 <Link to={'flight/passengers'}>常用旅客</Link>
    //             </p>
    //         </Segment>
    //     );
    // };

    // const Credit = () => {
    //     return (
    //         <Segment>
    //             <h3>授信额度</h3>
    //             <div>{data.user.agency.credit}</div>
    //         </Segment>
    //     );
    // };

    const handleLogout = () => {
        if (window.confirm('是否要登出?') == true) {
            dispatch(logout());
        }
    };

    const handleUpdate = () => {
        const req = {
            companyName: companyNameRef.current.value,
            contactName: contactNameRef.current.value,
            contactNumber: contactNumberRef.current.value,
        };
        setSubmitting(true);
        axios
            .patch(`${config.apiURL}/user/${data.user.id}`, req, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                setModalEditActive(false);
                fetchData();
                const reply = res.data.reply;
                if (reply) {
                    console.log(reply);
                    window.alert(reply);
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    // const handleCreate = () => {
    //     const req = {
    //         contactName: operatorReq.contactName,
    //         mobile: operatorReq.mobile,
    //         email: operatorReq.email,
    //         password: operatorReq.password,
    //         wechat: operatorReq.wechat,
    //     };
    //     console.log('creaete operator', req);
    //     setSubmitting(true);
    //     axios
    //         .post(`${config.apiURL}/users`, req, {
    //             signal: controller.signal,
    //             headers: { Authorization: `Bearer ${GetToken()}` },
    //         })
    //         .then((res) => {
    //             setModalActive(false);
    //             fetchData();
    //             const reply = res.data.reply;
    //             if (reply) {
    //                 alert(reply);
    //             }
    //         })
    //         .catch((err) => {
    //             let msg = err.toString();
    //             if (err.response?.data?.error) {
    //                 console.log('***', err.response.data.error);
    //                 msg = err.response.data.error;
    //             }
    //             window.alert(msg);
    //         })
    //         .finally(() => {
    //             setSubmitting(false);
    //         });
    // };

    return (
        <div className={styles.container}>
            <div className={styles.top_container}>
                <h3>
                    账号 <Loader active={fetching || submitting}></Loader>
                </h3>
                <Button className="primary_btn" onClick={handleLogout}>
                    登出
                </Button>
            </div>
            {data && (
                <>
                    {AccountInfo()}
                    {IsChannelUser() == true && <ChannelInfo />}
                    {/* {Credit()} */}
                    {/* <br /> */}
                    {/* {SubAccounts()} */}
                </>
            )}
            {/* {FlightSettings()} */}
            {/* <Segment>
                <Incentive />
            </Segment> */}

            {/* <Dimmer active={fetching || submitting} page inverted>
                <Loader></Loader>
            </Dimmer> */}
        </div>
    );
}

export default AccountPage;
